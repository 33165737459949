/*
 * CB Glyph Icons
 */

@use "sass:math";
@use "sass:map";

.cb-glyph {
  &:not(.cb-glyph-numeric) {
    @include glyph-font(CB Glyphs);
  }
}

.cb-glyph {
  @each $key, $value in $glyphs {
    &.cb-#{$key} {
      @include glyph-content($value);
    }
  }

  &.cb-search {
    background: none;
  }
}

/* ------------------------------------ NUMERIC */

.cb-glyph-numeric {
  @include border-box();

  display: inline-block;

  width: 2em;
  height: 2em;

  font-size: 1rem;
  font-weight: 700;
  line-height: 1.82em !important;
  text-align: center;

  border: 0.125em solid $font-color-base;
  border-radius: 50%;

  &::before {
    content: attr(data-cb-number);
  }

  &.cb-glyph-circular {
    color: $white;
    background-color: $blue5;
    border-color: $blue5;
  }
}

/* ------------------------------------ CIRCULAR */
.cb-glyph-circular {
  &:not(.cb-glyph-numeric) {
    position: relative;
    display: block;
    width: 2em;
    height: 2em;

    &::before {
      position: absolute;
      z-index: 2;
      top: math.div(1em, 2);
      left: math.div(1em, 2);

      color: $white;
    }

    &::after {
      content: " ";

      position: absolute;
      z-index: 1;

      display: block;

      width: 2em;
      height: 2em;

      background-color: $blue5;
      border-radius: 50%;
    }
  }
}

/* ------------------------------------ AVATAR */
.cb-glyph-avatar {
  &:not(.cb-glyph-numeric) {
    position: relative;

    display: block;

    width: 2em;
    height: 2em;

    font-size: 1rem;

    transition: all $transition-time-l ease;

    &::before {
      position: absolute;
      z-index: 2;
      top: math.div(0.5em, 2);
      left: math.div(0.5em, 2);

      font-size: 1.3125em;
      color: $white;
    }

    &::after {
      content: " ";

      position: absolute;
      z-index: 1;

      display: block;

      width: 2em;
      height: 2em;

      background-color: $black1;
      border-radius: 50%;
    }

    /* md up */
    @include media-breakpoint-up("md", $grid-breakpoints) {
      width: 3em;
      height: 3em;

      &::before {
        font-size: 2em;
      }

      &::after {
        width: 3em;
        height: 3em;
      }
    }
  }
}

/* ------------------------------------ OLD SIZE */

.cb-glyph,
.cb-glyph-numeric {
  &:not(.cb-glyph-avatar) {
    @each $key, $value in $glyph-size {
      &.cb-glyph-#{$key} {
        font-size: calc(1rem * #{$value});
      }
    }
  }
}

.cb-glyph {
  &.cb-glyph-avatar {
    &.cb-glyph-sm {
      width: 2em;
      height: 2em;

      &::before {
        top: math.div(0.5em, 2);
        left: math.div(0.5em, 2);
        font-size: 1.3125em;
      }

      &::after {
        width: 2em;
        height: 2em;
      }
    }

    &.cb-glyph-md {
      width: 3em;
      height: 3em;

      &::before {
        font-size: 2em;
      }

      &::after {
        width: 3em;
        height: 3em;
      }
    }
  }
}

/* ------------------------------------ NEW SIZE PATTERN */

$glyph-size-new: (12, 14, 16);

@each $size in $glyph-size-new {
  .cb-glyph {
    &.cb-glyph-#{$size} {
      font-size: #{$size}px;
    }

    &.cb-glyph-circular {
      $name: ($size * 2);

      &.cb-glyph-#{$name} {
        font-size: #{$size}px;
      }
    }
  }
}

/* Per breakpoint */
@each $breakpoint, $value in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    @each $size in $glyph-size-new {
      .cb-glyph-#{$breakpoint}-#{$size} {
        font-size: #{$size}px !important;
      }

      .cb-glyph-circular {
        $name: ($size * 2);

        &.cb-glyph-#{$breakpoint}-#{$name} {
          font-size: #{$size}px !important;
        }
      }
    }
  }

  /* UP */
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    @each $size in $glyph-size-new {
      .cb-glyph-#{$breakpoint}-up-#{$size} {
        font-size: #{$size}px !important;
      }

      .cb-glyph-circular {
        $name: ($size * 2);

        &.cb-glyph-#{$breakpoint}-up-#{$name} {
          font-size: #{$size}px !important;
        }
      }
    }
  }

  /* Down */
  @include media-breakpoint-down($breakpoint, $grid-breakpoints) {
    @each $size in $glyph-size-new {
      .cb-glyph-#{$breakpoint}-down-#{$size} {
        font-size: #{$size}px !important;
      }

      .cb-glyph-circular {
        $name: ($size * 2);

        &.cb-glyph-#{$breakpoint}-down-#{$name} {
          font-size: #{$size}px !important;
        }
      }
    }
  }
}

$num-glyph-size-new: (12, 14, 16, 24, 32);

@each $size in $num-glyph-size-new {
  .cb-glyph-numeric {
    $name: ($size * 2);

    &.cb-glyph-#{$name} {
      flex-shrink: 0;
      font-size: #{$size}px;
    }

    &.cb-glyph-circular {
      $name: ($size * 2);

      &.cb-glyph-#{$name} {
        flex-shrink: 0;
        font-size: #{$size}px;
      }
    }

    &.cb-glyph-24 {
      line-height: 23px !important;
    }
  }
}

/* Numeric Per breakpoint */
@each $breakpoint, $value in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    @each $size in $num-glyph-size-new {
      .cb-glyph-numeric {
        $name: ($size * 2);

        &.cb-glyph-#{$breakpoint}-#{$name} {
          flex-shrink: 0;
          font-size: #{$size}px !important;
        }

        &.cb-glyph-circular {
          $name: ($size * 2);

          &.cb-glyph-#{$breakpoint}-#{$name} {
            flex-shrink: 0;
            font-size: #{$size}px !important;
          }
        }
      }
    }
  }

  /* UP */
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    @each $size in $num-glyph-size-new {
      .cb-glyph-numeric {
        $name: ($size * 2);

        &.cb-glyph-#{$breakpoint}-up-#{$name} {
          flex-shrink: 0;
          font-size: #{$size}px !important;
        }

        &.cb-glyph-circular {
          $name: ($size * 2);

          &.cb-glyph-#{$breakpoint}-up-#{$name} {
            flex-shrink: 0;
            font-size: #{$size}px !important;
          }
        }
      }
    }
  }

  /* Down */
  @include media-breakpoint-down($breakpoint, $grid-breakpoints) {
    @each $size in $num-glyph-size-new {
      .cb-glyph-numeric {
        $name: ($size * 2);

        &.cb-glyph-#{$breakpoint}-down-#{$name} {
          flex-shrink: 0;
          font-size: #{$size}px !important;
        }

        &.cb-glyph-circular {
          $name: ($size * 2);

          &.cb-glyph-#{$breakpoint}-down-#{$name} {
            flex-shrink: 0;
            font-size: #{$size}px !important;
          }
        }
      }
    }
  }
}

$content-glyph-size-new: (32, 36, 48);

@each $size in $content-glyph-size-new {
  .cb-glyph-content {
    &.cb-glyph-#{$size} {
      font-size: #{$size}px;
    }
  }
}

/* Per breakpoint */
@each $breakpoint, $value in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    @each $size in $content-glyph-size-new {
      .cb-glyph-content {
        &.cb-glyph-#{$breakpoint}-#{$size} {
          font-size: #{$size}px !important;
        }
      }
    }
  }

  /* UP */
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    @each $size in $content-glyph-size-new {
      .cb-glyph-content {
        &.cb-glyph-#{$breakpoint}-up-#{$size} {
          font-size: #{$size}px !important;
        }
      }
    }
  }

  /* Down */
  @include media-breakpoint-down($breakpoint, $grid-breakpoints) {
    @each $size in $content-glyph-size-new {
      .cb-glyph-content {
        &.cb-glyph-#{$breakpoint}-down-#{$size} {
          font-size: #{$size}px !important;
        }
      }
    }
  }
}

$multi-glyph-size-new: (64, 72, 96);

@each $size in $multi-glyph-size-new {
  .cb-glyph-content-multi {
    &.cb-glyph-#{$size} {
      /* for when we have a flex parent */
      flex-shrink: 0;
      font-size: #{$size}px;
    }
  }
}

/* Per breakpoint */
@each $breakpoint, $value in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    @each $size in $multi-glyph-size-new {
      .cb-glyph-content-multi {
        &.cb-glyph-#{$breakpoint}-#{$size} {
          flex-shrink: 0;
          font-size: #{$size}px !important;
        }
      }
    }
  }

  /* UP */
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    @each $size in $multi-glyph-size-new {
      .cb-glyph-content-multi {
        &.cb-glyph-#{$breakpoint}-up-#{$size} {
          flex-shrink: 0;
          font-size: #{$size}px !important;
        }
      }
    }
  }

  /* Down */
  @include media-breakpoint-down($breakpoint, $grid-breakpoints) {
    @each $size in $multi-glyph-size-new {
      .cb-glyph-content-multi {
        &.cb-glyph-#{$breakpoint}-down-#{$size} {
          flex-shrink: 0;
          font-size: #{$size}px !important;
        }
      }
    }
  }
}

/* ------------------------------------ COLOR */

/* Removing cb-glyph-corporate , XXX color options */
@each $color, $map in $color-themes {
  .cb-glyph {
    &.#{"cb-" + $color + "-color"} {
      &.cb-glyph-circular,
      &.cb-glyph-avatar {
        &::after {
          background-color: map.get($map, code);
        }
      }
    }
  }

  .cb-glyph-numeric {
    &.#{"cb-" + $color + "-color"} {
      border-color: map.get($map, code);

      &.cb-glyph-circular {
        color: $white !important;
        background-color: map.get($map, code);
        border-color: map.get($map, code);
      }
    }
  }
}

@media print {
  @include print-glyph-numeric();
}

.cb-print {
  @include print-glyph-numeric();
}
