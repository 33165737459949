/*
 * CB Glyph
 */

@import "apricot-variable";
@import "../mixins/font";
@import "../mixins/glyph";
@import "../mixins/breakpoint";

@import "glyph-icons";
@import "glyph-content";
@import "glyph-content-multi";

@include font-face('CB Glyphs', cb-glyphs, null, normal, woff ttf);
@include font-face('CB Glyphs Multi', cb-glyphs-multi, null, normal, woff ttf);

